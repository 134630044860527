import React from "react";
import StaticPageLayout from "../../components/StaticPages/Layout";

import { BannerChurch, Logo2, Proboszcz } from "../../img";

const Information: React.FC = () => {
  return (
    <StaticPageLayout title="Informacje" background={BannerChurch}>
      <div
        id="InfoContainer"
        className="flex flex-col md:flex-row md:flex-wrap text-foreground justify-around gap-6 items-center"
      >
        <div className="flex flex-col lg:basis-[26%] gap-y-3">
          <div className="flex flex-row w-full justify-center">
            <img src={Logo2} alt="Logo" className="w-48 h-48" />
          </div>
          <div className="flex flex-col items-start gap-y-2">
            <div>
              <h3 className="boldbasetext">
                Parafia Ewangelicko-Augsburska Bielsko
              </h3>
              <address className="basetext">
                <a href="https://maps.app.goo.gl/NhPcUi1VkXVpt7dN8">
                  pl. ks. Marcina Lutra 12
                  <br />
                  43-300 Bielsko-Biała
                </a>
                <br />
                Telefon: &emsp;
                <a href="tel:+48338227471" className="clickable">
                  33 82 274 71
                </a>
                &emsp;
                <a href="tel:+48519048551" className="clickable">
                  519 048 551
                </a>
                <br />
                E-mail:&emsp;
                <a href="mailto:bielsko@luteranie.pl" className="clickable">
                  bielsko@luteranie.pl
                </a>
                <br />
              </address>
            </div>

            <p>
              <span className="boldbasetext">
                Kancelaria Parafialna - czynna:
              </span>
            </p>
            <table className="border-separate border-spacing-x-3">
              <tbody>
                <tr>
                    <td>Poniedziałek:</td>
                    <td>9.00 - 15.00</td>
                </tr>
                <tr>
                    <td>Wtorek:</td>
                    <td>9.00 - 15.00</td>
                </tr>
                <tr>
                    <td>Środa:</td>
                    <td>9.00 - 15.00</td>
                </tr>
                <tr>
                    <td>Czwartek:</td>
                    <td>9.00 - 17.00</td>
                </tr>
                <tr>
                    <td>Czwartek:</td>
                    <td className="boldbasetext">Kancelaria nieczynna</td>
                </tr>
                <tr>
                    <td>Czwartek:</td>
                    <td className="boldbasetext">Kancelaria nieczynna</td>
                </tr>
                <tr>
                    <td>Niedziela:</td>
                    <td>
                    8.30 - 9.00;
                    <br />
                    <span className="italic">(po nabożeństwie)</span>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-col lg:basis-[26%]">
          <p>
            <span className="boldbasetext">NIP</span>: 547 02 93 189
            <br />
            <span className="boldbasetext">REGON:</span> 0704 11 941
            <br />
            <br />
            <span className="boldbasetext">Numer kont :</span>
            <br />
            <br />
            <span className="boldbasetext">KONTO GŁÓWNE</span>
            <br />
            PKO BP: 41 1020 1390 0000 6502 0143 4489
            <br />
            <br />
            <span className="boldbasetext">KONTO ORGANOWE</span>
            <br />
            PKO BP: 72 1020 1390 0000 6302 0427 6614
            <br />
            <br />
            <span className="boldbasetext">KONTO OGRODY PAMIĘCI</span>
            <br />
            PKO BP: 45 1020 1390 0000 6802 0497 7098
            <br />
            <br />
            <span className="boldbasetext">KONTO NOWE CMENTARZE</span>
            <br />
            PKO BP: 35 1020 1390 0000 6902 0550 8595
            <br />
            <br />
            <span className="boldbasetext">KONTO DZIAŁALNOŚĆ GOSPODARCZA</span>
            <br />
            PKO BP: 96 1020 1390 0000 6702 0580 1602
            <br />
          </p>
        </div>
        <div className="flex flex-col lg:basis-[26%] gap-y-3">
          <h3 className="boldbasetext">Ks. Krzysztof Cienciała (proboszcz)</h3>
          <div className="flex flex-row w-full justify-center">
            <img
              src={Proboszcz}
              alt="Ks. Krzysztof Cienciała"
              className="w-48 h-48"
            />
          </div>
          <p>
            ks. Krzysztof Cienciała (proboszcz)
            <br />
            tel.{" "}
            <a href="tel:+48519048552" className="boldbasetext clickable">
              519 048 552
            </a>
            <br />
          </p>
        </div>
      </div>
    </StaticPageLayout>
  );
};

export default Information;
