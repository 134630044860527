import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { PersonFillIcon } from "../../img";
import { useAuth } from "../../contexts/AuthContext";

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const navigate = useNavigate();
  const auth = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!username || !password) {
      setError("Please fill in all fields");
      return;
    }

    try {
      const result: [boolean, string] = await auth.login(username, password);
      if (!result[0]) {
        if (result[1] == "401") setError(`Invalid credentials`);
        else setError(`Error when logging in: ${result[1]}`);

        return;
      }

      navigate("/admin/dashboard");
    } catch (error) {
      setError(`Invalid credentials: ${error}`);
    }
  };

  return (
    <main className="flex flex-col w-full h-[100vh]">
      <div className="flex flex-col mx-auto my-auto p-4 rounded-2xl bg-gray-500 gap-y-4">
        <div className="flex flex-col gap-y-3">
          <PersonFillIcon className="w-20 h-20 mx-auto fill-background" />
          <p className="text-background semiboldheader3">
            Log in to the administrator panel
          </p>
        </div>
        <form
          className="flex flex-col gap-y-3 text-background basetext items-center"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col justify-start gap-y-1">
            <label htmlFor="username" className="boldbasetext">
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Username"
              className="inputField"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="flex flex-col justify-start gap-y-1">
            <label htmlFor="password" className="boldbasetext">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              className="inputField"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && (
            <p className="text-boldbasetext p-2 rounded-md border-2 border-danger text-background w-max">
              {error}
            </p>
          )}
          <div className="flex flex-col justify-center items-center mt-2 gap-y-1 m-auto">
            <button
              type="submit"
              className="p-2 rounded-md bg-background text-foreground boldbasetext"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default Login;
