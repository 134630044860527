import React from "react";

import StaticPageLayout from "../../components/StaticPages/Layout";
import { BannerLuter, Logo2, SeniorBanner } from "../../img";

const Senior: React.FC = () => {
  return (
    <StaticPageLayout background={BannerLuter} title={"Senior"}>
      <div className="w-full flex flex-row justify-center">
        <img src={SeniorBanner} alt="Banner sponsora" />
      </div>
      <p>
        Parafia Ewangelicko-Augsburska Bielsko realizuje projekt pt &quot;Standard
        opieki senioralnej w Bielsku-Białej&quot; finansowany ze środków
        Europejskiego Funduszu Społecznego w ramach poddziałania: 9.2.5. Rozwój
        usług społecznych Regionalnego Programu Operacyjnego dla Województwa
        Śląskiego
        <br />
        <br />
        Całkowita wartość projektu: 754 643,75 zł.
        <br />
        Wkład Funduszy Europejskich: 641 447,18 zł.
        <br />
        Dysponenci budżetu państwa: 60 371,51 zł.
        <br />
        Wkład własny: 52 825,06 zł.
        <br />
        <br />
        Projekt dotyczy wsparcia w samodzielności, niezależności, utrzymaniu lub
        przywróceniu wysokiej jakości życia pomimo ograniczeń związanych z
        procesem starzenia się 50 osób 60+ zamieszkujących Miasto Bielsko-Białą
        oraz wsparcia 20 opiekunów faktycznych w sprawowaniu opieki nad swoimi
        bliskimi w miejscu zamieszkania w formie następujących form wskazanych w
        Regulaminie konkursu jak i Skoordynowanej Opiece Senioralnej (SOS):
        <br />
        <br />
        1.Klub Seniora: gimnastyka/rehabilitacja, zajęcia plastyczne, grupa
        terapeutyczno-rozwojowe, muzykoterapia, wycieczki, wieczorki taneczne
        dla 20 uczestników;
        <br />
        2.Realizacja usług opiekuńczych świadczonych w miejscu zamieszkania dla
        15 uczestników;
        <br />
        3.Wsparcie 20 opiekunów faktycznych w formie doradztwa
        specjalistycznego: prawnik, pracownik socjalny,
        psycholog/psychoterapeuta; konsultacje pielęgniarsko-opiekuńcze, life
        coaching w średnim wymiarze około 10 godzin miesięcznie;
        <br />
        4.Dowożenie posiłków do miejsc zamieszkania dla 10 uczestników.
        <br />
        <br />
        Projekt będzie realizowany w okresie 1 wrzesień 2018 do 31 grudzień 2020
        roku.(przedłużenie do marca 2021 r.)
        <br />
        <br />
        Parafia Ewangelicko -Augsburska Bielsko, pl. Marcina Lutra 12 w
        Bielsku-Białej
        <br />
        wyraża gotowość zachowania trwałości liczby miejsc świadczenia usług
        społecznych w ramach Klubu Seniora oraz usług opiekuńczych.
        <br />
      </p>
      <p>
        Zapytanie ofertowe z dnia 30.11.2019
        <br />
        Parafia Ewangelicko-Augsburska Bielsko zaprasza do składania ofert na
        wykonanie prac remontowych celem przekształcenia lokalu mieszkalnego w
        parafialny klub seniora.
        <br />
        Treść ogłoszenia została zmieniona
        <br />
        1) Zmieniono plik Przedmiar robót (poprzednia wersja pliku była
        uszkodzona) 2) Na wniosek podmiotu zainteresowanego dodano Przedmiar z
        oznaczeniami KNR i KSNR (jako osobny plik)
        <br />
        Załączniki:
        <br />
        Zapytanie roboty budowlane senior
        <br />
        {/* TODO: Add these files to server dir */}
        Załącznik nr 1 - Projektu budowlany
        <br />
        Załącznik nr 1 - Przedmiar robót
        <br />
        Załącznik nr 2 - formularz oferty
        <br />
        Załącznik nr 3 - wzór umowy
        <br />
        Załącznik nr 4 - harmonogram prac
        <br />
        Przedmiar z oznaczeniami KNR i KSNR
        <br />
        <br />
        Ogłoszenie -<br />
        Parafia realizuje projekt pt &quot;Standard opieki senioralnej w
        Bielsku-Białej&quot; finansowany ze środków Europejskiego Funduszu
        Społecznego. W związku z tym
        <br />
        Zapraszamy opiekunów faktycznych do zgłaszania swoich starszych
        rodziców, osoby samotne wymagające opieki by skorzystały z usług
        opiekuńczych realizowanych przez wykwalifikowany personel opiekunek.
        <br />
        Średnia ilość godzin miesięcznie : 15 na jedna osobę
        <br />
        Opiekun świadczy usługi BEZPŁATNIE w miejscu zamieszkania, pomaga w
        czynnościach życia codziennego: mycie, sprzątanie, karmienie, gotowanie
        posiłków ew. robienie zakupów.
        <br />
        <br />
        W ramach projektu powstanie Klub Seniora dla 20 uczestników - planowany
        czas rozpoczęcia maj 2019, dowożone są posiłki, prowadzone jest
        doradztwo psychologiczno-prawne.
        <br />
        Termin zgłaszanie chętnych do 28 lutego. Ilość miejsc ograniczona.
        <br />
        Bezpośrednio po zakończeniu nabożeństwa można zgłaszać osoby
        zainteresowane oraz telefonicznie, bezpośrednio lub mailowo do EDO Soar
        do pani Beaty Pawlak- Młodzik, tel 33 812 6164, mail: domsoar@o2.pl.
        Proszę podać imię i nazwisko, adres i numer kontaktowy. Z osobami
        zainteresowanymi będziemy umawiać się na spotkanie.
        <br />
      </p>
      <p>
        <span className="semiboldheader4">
          Sprawozdanie z realizacji projektu &quot;Standard opieki senioralnej w
          Bielsku Białej&quot;
        </span>
        <br />
        Wartość projektu: dofinansowanie - 701 818,69zł; wkład własny - 52
        825,06zł.
        <br />
        Łączna kwota wydatków do 28.02.2019 - 114 382,80zł w tym 25 % kosztów
        pośrednich
        <br />
        (zarządzanie, księgowość, obsługa administracyjna i prowadzenie biura)
        <br />
        <br />
        <span className="boldbasetext">Zadanie 1</span>
        <br />
        Klub Seniora: etap 1 remont, etap 2 - działalność Klubu Koszt - 75 000zł
        (tylko koszty poniesione w projekcie)
        <br />
        W ramach projektu założono remont mieszkania - pomieszczenia na terenie
        budynku parafialnego przy Placu Marcina Lutra 2.
        <br />
        Prace zostały rozpoczęte z opóźnieniem - w grudniu (zapytanie ofertowe z
        dnia 30.11.2018) ze względu na konieczność dopełnienia formalności
        związanych z pozwoleniami, ponowną oceną zakresu prac. Budynek jest
        objęty ochroną konserwatora zabytków i wszelkie prace w nim prowadzone
        odbywają się pod nadzorem. Budynek jest wiekowy więc podczas prac
        remontowych pojawiły się nieprzewidziane przeszkody, które utrudniały
        kontynuację prac. Zakres wykonanych robót na dzień 28.02.2019r. to:
        wymiana instalacji elektrycznej, przygotowanie ścian pod gładź i
        rozpoczęcie procesu kładzenia gładzi, odsłonięcie sufitów, belek
        stropowych i ich zabezpieczenie, rozbiórka na podstawie ekspertyzy
        nadzoru budowlanego.
        <br />
        Przewidywany termin uruchomienia Klubu to 1 czerwiec 2019r. zakończenie
        projektu to grudzień 2020r.
        <br />
        W ramach klubu będą odbywać się następujące zajęcia:
        <br />
        - gimnastyka kondycyjno-rehabilitacyjna - 1 x tyg. 2h.
        <br />
        - zajęcia plastyczne 1x tyg. 2h.
        <br />
        - grupa terapeutyczno-rozwojowa - 1x tyg. 3h.
        <br />
        - muzykoterapia - 1 x tyg. 2h - elementy relaksacji, realizacja pasji
        muzycznych.
        <br />
        W czasie kiedy nie są prowadzone regularnie zajęcia, każdy z Uczestników
        będzie miał do dyspozycji: kącik z książkami, możliwość posłuchania
        muzyki, kącik rozmów, gry planszowe. Nad wszystkim będzie czuwał opiekun
        grupy (1 etat). Podczas pobytu w Klubie każdy z Uczestników otrzyma
        drobny poczęstunek i jeden ciepły posiłek w sytuacji gdy będzie
        przebywać dłużej niż 5 godzin. Przewidujemy organizację 3 jednodniowych
        wycieczek turystyczno-krajoznawczych.
        <br />
        Uczestnicy będą mogli wziąć udział w 5 wieczorkach tanecznych.
        <br />
        <br />
        <span className="boldbasetext">Zadanie 2</span>
        <br />
        Usługi opiekuńcze - koszt 8 706,24zł.
        <br />
        W ramach zadania, w bieżącym okresie rozliczeniowym przeprowadzono 80h
        szkolenia dla opiekunów osób starszych, zgodnie ze Standardem Opieki
        Senioralnej. Uczestnicy otrzymali rzetelną wiedzę teoretyczną a przede
        wszystkim praktyczną w temacie sprawowania opieki nad osobą starszą,
        leżącą. Odbył się również trening umiejętności interpersonalnych.
        Podczas warsztatów opiekunowie otrzymali poczęstunek i materiały
        szkoleniowe.
        <br />
        Mamy już zamkniętą listę uczestników objętych usługami - 15 osób. Usługi
        ruszą najpóźniej 1 maja 2019r. Każda osoba otrzyma średnio 15-17h usług
        na miesiąc. Przewidujemy pobieranie drobnych opłat ok. 2 zł/h
        (konieczność wynikająca z zapisów regulaminu konkursu, z ramienia
        którego realizowany jest projekt)
        <br />
        <br />
        <span className="boldbasetext">Zadanie 3</span>
        <br />
        Doradztwo dla opiekunów faktycznych - koszt 0,00zł
        <br />
        Wraz z uczestnikami usług opiekuńczych będziemy realizować usługi
        doradcze.
        <br />
        Doradztwo będzie odbywać się na rzecz opiekunów faktycznych osób
        starszych, którymi są dzieci, znajomi, dalsi krewni. Planowani
        specjaliści to: psycholog, coach, prawnik.
        <br />
        <br />
        <span className="boldbasetext">Zadanie 4</span>
        <br />
        Dowożenie posiłków - koszt 7 800zł
        <br />
        <br />
        Posiłki są dowożone od 6 grudnia 2018r. do 10 osób zamieszkujących
        miasto Bielsko-Białą. Są to osoby leżące lub mające trudności z
        przemieszczaniem się i w trudniejszej sytuacji finansowej. Posiłek
        ciepły, przygotowywany w danym dniu zgodnie z przepisami sanitarnymi.
        Posiłki w bieżącym okresie rozliczeniowym były przygotowywane i dowożone
        przez Dom SOAR i rozliczane wewnętrznym dokumentem - nota księgową. W
        związku z tym był to posiłek dwudaniowy bo takie rozwiązanie pozwoliło
        wyjść naprzeciw oczekiwaniom klientów.
        <br />
        Od 22 marca posiłki są zlecane firmie cateringowej i realizowane bez
        naruszenia jakości.
        <br />
      </p>
      <div className="flex flex-row w-full justify-center">
        <img
          src={Logo2}
          alt="Logo Parafii Ewangelicko-Augsburskiej w Bielsku-Białej"
          className="max-w-[180px] md:max-w-[360px]"
        />
      </div>
    </StaticPageLayout>
  );
};

export default Senior;
