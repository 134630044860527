import React, { useEffect, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import { Navbar, Footer } from "./components";

import Graduale from "./pages/static/Graduale";
import Devotions from "./pages/static/Devotions";
import Offering from "./pages/static/Offering";
import Soar from "./pages/static/Soar";
import Senior from "./pages/static/Senior";
import Information from "./pages/static/Information";

import History from "./pages/posts/History";
import Publications from "./pages/posts/Publications";
import News from "./pages/posts/News";

import LandingPage from "./pages/LandingPage";
import PostDetail from "./pages/PostDetail";
import NotFound from "./pages/misc/NotFound";

// Admin pages
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./components/Admin/PrivateRoute";
import Dashboard from "./pages/admin/Dashboard";
import Login from "./pages/admin/Login";
import Sidebar from "./components/Admin/Sidebar";
import Callendar from "./pages/posts/Callendar";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* ADMIN PAGES */}

        <Route
          path="/admin/*"
          element={
            <AuthProvider>
              {" "}
              <PrivateRoute />{" "}
            </AuthProvider>
          }
        >
          <Route path="login" Component={Login} />
          <Route path="*" element={<AdminAnimatedTransition />} />
        </Route>

        {/* USER PAGES */}
        <Route path="*" element={<PublicAnimatedTransition />} />
      </Routes>
    </Router>
  );
};

const PublicAnimatedTransition: React.FC = () => {
  const location = useLocation();
  const [nodeRefs, setNodeRefs] = useState(
    new Map<string, React.RefObject<HTMLDivElement>>()
  );

  useEffect(() => {
    if (!nodeRefs.has(location.key)) {
      setNodeRefs((prev) => new Map(prev).set(location.key, React.createRef()));
    }
  }, [location, nodeRefs]);

  const nodeRef = nodeRefs.get(location.key);

  return (
    <div className="flex flex-col justify-start w-full min-h-[100vh] overflow-x-hidden">
      <Navbar />
      <TransitionGroup className="w-full h-full my-auto">
        {nodeRef && (
          <CSSTransition
            key={location.key}
            timeout={300}
            classNames="fade"
            nodeRef={nodeRef}
          >
            <div ref={nodeRef} className="w-full h-full">
              <Routes location={location}>
                {/* STATIC PAGES */}
                <Route path="/" Component={LandingPage} />
                <Route path="/information" Component={Information} />
                <Route path="/news" Component={News} />
                <Route path="/calendar" Component={Callendar} />

                <Route path="/history" Component={History} />
                <Route path="/choir" Component={Graduale} />
                <Route path="/publications" Component={Publications} />
                <Route path="/devotions" Component={Devotions} />
                <Route path="/offerings" Component={Offering} />

                <Route path="/soar" Component={Soar} />
                <Route path="/senior" Component={Senior} />

                {/* DYNAMIC PAGES */}
                <Route path="post/:slug" Component={PostDetail} />

                {/* ERROR PAGES */}
                <Route path="/404-not-found" Component={NotFound} />
                <Route path="*" Component={NotFound} />
              </Routes>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      <Footer />
    </div>
  );
};

const AdminAnimatedTransition: React.FC = () => {
  const location = useLocation();

  return (
    <div className="flex flex-row justify-start w-full overflow-x-hidden">
      <Sidebar />
      <TransitionGroup className="ml-[200px] w-full">
        <CSSTransition key={location.key} timeout={300} classNames="fade">
          <Routes location={location}>
            <Route path="dashboard" Component={Dashboard} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default App;
