import React, { useState, useEffect } from "react";
import { redirect, useParams } from "react-router-dom";

import { getPostContent, getPosts } from "../services/postService";
import { PostContentType, PostType } from "../types/Post";
import {
  HeroBanner,
  BannerOld,
  BannerLuter,
  BannerBook,
  BannerKeys,
  BannerClock,
} from "../img";

import Loading from "../components/ui/Loading";
import StaticPageLayout from "../components/StaticPages/Layout";

const PostDetail: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<PostType>();
  const [postContent, setPostContent] = useState<PostContentType[]>([]);
  const backgrounds = [
    HeroBanner,
    BannerOld,
    BannerLuter,
    BannerBook,
    BannerKeys,
    BannerClock,
  ];
  const [background, setBackground] = useState<string>(HeroBanner);

  useEffect(() => {
    const fetchPost = async () => {
      if (!slug) {
        redirect("/404-not-found");
        return;
      }

      const post_response = await getPosts(undefined, slug);

      if (post_response.length === 0) {
        redirect("/404-not-found");
        return;
      }
      setPost(post_response[0]);

      const post_content_response = await getPostContent(slug);
      setPostContent(post_content_response);
    };

    fetchPost();
    setBackground(backgrounds[Math.floor(Math.random() * backgrounds.length)]);
  }, []);

  if (!post) {
    return <Loading />;
  }

  return (
    <StaticPageLayout title={post.title} background={background}>
      {post.description}
      {postContent.map((content, index) => (
        <div key={index}>{content.text}</div>
      ))}
    </StaticPageLayout>
  );
};

export default PostDetail;
