import React from "react";

const Loading: React.FC = () => {
  return (
    <div className="flex flex-col items-center my-auto">
      <h2 className="semiboldheader1">Loading...</h2>
    </div>
  );
};

export default Loading;
