import React, { useState } from "react";

import StaticPageLayout from "../../components/StaticPages/Layout";
import { BannerLuter } from "../../img";

const Offering: React.FC = () => {
  const [amount, setAmount] = useState<string>("20.00");

  return (
    <StaticPageLayout title="Złóż ofiarę" background={BannerLuter}>
      <div className="flex flex-row w-full">
        <div className="flex flex-col gap-y-2">
          <h3 className="semiboldheader3">Drodzy Parafianie</h3>
          <p className="basetext">
            Dla wszystkich, którzy czują potrzebę wsparcia naszej Parafii
            oddajemy możliwość dokonywania dowolnych wpłat (darowizn) poprzez
            bezpieczne płatności internetowe DotPay. Wszelkie datki otrzymują
            opis transakcji jako &quot;darowizna na cele kultu religijnego&quot;
            uprawniające do uwzględnienia jako ulgę w rozliczeniu rocznym z
            Urzędem Skarbowym.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-y-4 md:gap-x-6 md:justify-around">
        <form
          className="flex-col gap-y-3 lg:basis-1/3"
          method="post"
          action="https://ssl.dotpay.pl/t2/"
        >
          <h4 className="semiboldheader4">Przelew internetowy</h4>
          <div className="flex flex-col p-4 gap-y-2 bg-background rounded-2xl">
            <p className="text-foreground basetext">Wybierz kwotę darowizny</p>
            <div className="flex flex-row gap-x-1 items-center">
              <input
                type="button"
                className={`rounded-lg p-2 bg-purple-500 text-background cursor-pointer ${
                  amount === "10.00" ? "font-bold" : ""
                }`}
                onClick={() => setAmount("10.00")}
                value="10.00 zł"
              />
              <input
                type="button"
                className={`rounded-lg p-2 bg-purple-600 text-background cursor-pointer ${
                  amount === "20.00" ? "font-bold" : ""
                }`}
                onClick={() => setAmount("20.00")}
                value="20.00 zł"
              />
              <input
                type="button"
                className={`rounded-lg p-2 bg-purple-800 text-background cursor-pointer ${
                  amount === "50.00" ? "font-bold" : ""
                }`}
                onClick={() => setAmount("50.00")}
                value="50.00 zł"
              />
            </div>
            <div className="flex flex-row w-full justify-center">
              <p className="basetext">lub</p>
            </div>
            <div className="flex flex-row w-full gap-x-1 items-center">
              <p className="basetext h-max">Własna kwota:</p>
              <input
                id="dp_kwota"
                type="text"
                name="amount"
                className="basetext bg-background text-foreground border-2 border-purple-500 p-2 rounded-lg"
                pattern="^([1-9])((\.\d{1,2})?)$|^((?!0)(\d){1,5})((\.\d{1,2})?)$|^(1(\d{5})(.\d{1,2})?)$|^(200000(.[0]{1,2})?)$"
                placeholder="20.00"
                maxLength={9}
                size={5}
                title="Kwota powinna zawierać się w przedziale 1 - 200000 PLN. Dozwolony format to np: 100 lub 152.43"
                onInput={(e) => {
                  const target = e.target as HTMLInputElement;
                  target.value = target.value.replace(/[^0-9\.]/g, "");
                  target.value = target.value.replace(/(\..*)\./g, "$1");
                  setAmount(target.value);
                }}
                value={amount}
              />
              <p className="basetext h-max">PLN</p>
            </div>
            <input type="hidden" name="id" value="291838" />
            <input
              type="hidden"
              name="description"
              value="Darowizna na cele kultu religijnego"
            />
            <input
              type="hidden"
              name="url"
              value="http://bielsko.luteranie.pl/"
            />{" "}
            {/* Add a thank you page here */}
            <input
              type="hidden"
              name="buttontext"
              id="buttontext"
              value="Powrót do sprzedawcy"
            />
            <input type="hidden" name="type" value="0" />
            <input type="hidden" name="currency" value="PLN" />
            <div className="flex flex-row w-full justify-start">
              <button
                type="submit"
                className="rounded-lg p-2 bg-purple-500 text-background cursor-pointer"
              >
                Wpłać darowiznę
              </button>
            </div>
          </div>
        </form>
        <div className="flex-col gap-y-3 lg:basis-1/3">
          <h4 className="semiboldheader4">Przelew tradycyjny</h4>
          <div className="flex flex-col p-4 gap-y-2 bg-background rounded-2xl">
            <p className="basetext">
              Stale udostępniamy możliwość przekazywania wpłat za pomocą
              tradycyjnych przelewów na rachunek naszej Parafii.
              <br />
              <br />
              <span className="boldbasetext">Dane do przelewu:</span>
              <br />
              Parafia Ewangelicko-Augsburska Bielsko
              <br />
              pl. ks. Marcina Lutra 12
              <br />
              43-300 Bielsko - Biała
              <br />
              41 1020 1390 0000 6502 0143 4489 <br />
              <span className="boldbasetext">Tytuł przelewu:</span>
              <br />
              Darowizna na cele kultu religijnego
              <br />
            </p>
          </div>
        </div>
      </div>
    </StaticPageLayout>
  );
};

export default Offering;
