import React from "react";
import { Link } from "react-router-dom";

import { DashboardIcon } from "../../img";

const Sidebar: React.FC = () => {
  return (
    <nav className="fixed h-[100vh] md:min-w-[200px] bg-gray-500 p-8">
      <div className="flex flex-col gap-y-6">
        <h2>
          Parafia
          <br />
          Bielsko
        </h2>
        <div className="flex flex-row gap-y-4">
          <LinkLabel
            to="admin/dashboard/"
            label="Dashboard"
            icon={<DashboardIcon className="w-6 h-6" />}
            className="text-background semiboldheader4"
          />
        </div>
      </div>
    </nav>
  );
};

type LinkLabelProps = {
  to: string;
  label: string;
  icon: React.ReactNode;
  className?: string;
};

const LinkLabel: React.FC<LinkLabelProps> = ({
  to,
  label,
  icon,
  className = "text-background semiboldheader4",
}) => {
  return (
    <Link to={to} className="flex flex-row gap-x-2">
      {icon}
      <p className={className}>{label}</p>
    </Link>
  );
};

export default Sidebar;
