import React, { useEffect, useState } from "react";

import { BannerOld } from "../../img";
import Post from "../ui/Post";
import { PostType } from "../../types/Post";
import { getPinnedPosts } from "../../services/postService";

const News: React.FC = () => {
  const [posts, setPosts] = useState<PostType[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await getPinnedPosts("LandingPage");

      setPosts(response);
    };
    fetchPosts();
  }, []);

  return (
    <div
      id="NewsContainer"
      className="relative w-full bg-cover bg-center"
      style={{ backgroundImage: `url(${BannerOld})` }}
    >
      <div className="absolute backgroundImageFilterNews w-full" />
      <div className="absolute backgroundImageFilterLight w-full" />
      <div
        id="News"
        className="relative flex flex-col gap-y-6 object-cover w-full"
      >
        <div
          id="NewsTitle"
          className="flex flex-row px-8 md:px-[11.25%] justify-end"
        >
          <h2 className="text-purple-500 boldheader3 md:boldheader2">
            Aktualności
          </h2>
        </div>
        <div
          id="NewsContent"
          className="flex flex-col lg:flex-row items-center lg:justify-between gap-y-4 px-8 md:px-[11.25%]"
        >
          {/* TODO: Fetch posts from database */}
          {posts.length === 0 &&
            [...Array(3)].map((_, index) => <Post key={index} />)}
          {posts &&
            posts.map((post, index) => <Post post={post} key={index} />)}
        </div>
      </div>
    </div>
  );
};

export default News;
