import React from "react";

import { BannerOld, DevotionsPlan } from "../../img";
import StaticPageLayout from "../../components/StaticPages/Layout";

const Devotions: React.FC = () => {
  return (
    <StaticPageLayout background={BannerOld} title={"Nabożeństwa"}>
      <p>
        Nabożeństwa w każdą niedzielę o godzinie 9.00 <br />
        Spowiedź i Komunia Święta w pierwszą niedzielę miesiąca, w czasie
        Adwentu i Pasji zapraszamy do Stołu Pańskiego w każdą niedzielę. <br />
        <br />
        Tygodniowe nabożeństwa adwentowe - środa godz. 17.00
        <br />
        Tygodniowe nabożeństwa pasyjne - piątek godz. 17.00
        <br />
        <br />
        Spotkania stałe
        <br />
        <br />
        SERDECZNIE ZAPRASZAMY!
      </p>
      <div className="flex flex-row w-full justify-center">
        <img
          src={DevotionsPlan}
          alt="Plan nabożeństw"
          className="w-full max-w-[920px]"
        />
      </div>
    </StaticPageLayout>
  );
};

export default Devotions;
