import { api } from './api';
import { PostType, PostContentType } from '../types/Post';

export const getPinnedPosts = async (page: string): Promise<PostType[]> => {
    const url = `/posts/pinned?page=${page}`;
    const response = await api.get(url);

    return response.data as PostType[];
}
export const getPosts = async (page?: string, slug?: string, eventType?: string, eventDate?: Date): Promise<PostType[]> => {
    let url = `/posts`;
    url = page ? url.concat(`?page=${page}`) : url;
    url = slug ? url.concat(`?slug=${slug}`) : url;
    url = eventType ? url.concat(`?eventType=${eventType}`) : url;
    url = eventDate ? url.concat(`?eventDate=${eventDate}`) : url;

    const response = await api.get(url);

    if (response.status !== 200) {
        console.error("Error fetching posts {%s}", response.data["detail"]);
        return [] as PostType[];
    }

    return response.data as PostType[];
}
export const getPostContent = async (slug: string): Promise<PostContentType[]> => {
    const url = `/posts/content?slug=${slug}`;

    const response = await api.get(url);

    if (response.status !== 200) {
        console.error("Error fetching post content {%s}", response.data["detail"]);
        return [] as PostContentType[];
    }

    return response.data as PostContentType[];
}