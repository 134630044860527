import React from "react";

const Dashboard: React.FC = () => {
  return (
    <main>
      <h1>Dashboard</h1>
    </main>
  );
};

export default Dashboard;
