import React, { useState, useEffect } from "react";

import PostPageLayout from "../../components/PostPages/Layout";
import Post from "../../components/ui/Post";
import { getPosts } from "../../services/postService";
import { PostType } from "../../types/Post";
import { BannerChurch } from "../../img";

const Callendar: React.FC = () => {
  const [posts, setPosts] = useState<PostType[]>([]);

  const [PostType, setPostType] = useState<string>();
  const [PostDate, setPostDate] = useState<Date>(new Date());

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await getPosts("Wydarzenia", undefined, PostType, PostDate);

      setPosts(response);
    };
    fetchPosts();
  }, []);

  return (
    <PostPageLayout title="Kalendarium" backgroundImage={BannerChurch}>
      <div className="flex flex-col md:flex-row p-4 w-full justify-center bg-purple-50 rounded-2xl gap-x-4 gap-y-4 items-center">
        <div className="flex flex-row gap-x-2 text-foreground basetext items-center">
            <p className="w-max text-lg">Typ Wydarzenia</p>
            <select
              className="selector"
              onChange={(e) => setPostType(e.target.value)}
              >
                <option hidden disabled selected value="all">Wybierz</option>
                <option value="concert">Koncert</option>
                <option value="meeting">Spotkanie</option>
                <option value="other">Inne</option>
              </select>
        </div>
        <div className="flex flex-row gap-x-2 text-foreground basetext items-center">
            <p className="w-max text-lg">Data</p>
            <input
              type="date"
              className="w-max bg-purple-100 p-2 border-2 rounded-md border-purple-500"
              onChange={(e) => setPostDate(new Date(e.target.value))}
            />
        </div>
      </div>
      {posts.length === 0
        ? <div className="flex flex-col min-h-48 items-center justify-center w-full">
            <div className="p-4 rounded-2xl bg-purple-50">
                <h2 className="text-foreground semiboldheader3 md:semiboldheader2">Brak wydarzeń do wyświetlenia</h2>
            </div>
        </div>
        : posts.map((post, index) => (
            <Post
              post={post}
              key={index}
              className="basis-[51%] lg:basis-[34%] 2xl:basis-[26%] grow-1 mx-auto"
            />
          ))}
    </PostPageLayout>
  );
};

export default Callendar;
