import { PageColors } from "./Pages";

export {
    PageColors,
}

export const NavigationLinks = [
    {
        name: "Informacje",
        path: "/information",
    },
    {
        name: "Ogłoszenia",
        path: "/news",
    },
    {
        name: "Kalendarium",
        path: "/calendar"
    },
    {
        name: "Parafia",
        links: [
            {
                name: "Historia",
                path: "/history"
            },
            {
                name: "Chór",
                path: "/choir"
            },
            {
                name: "Publikacje",
                path: "/publications"
            },
            {
                name: "Nabożeństwa",
                path: "/devotions",
            },
            {
                name: "Złóż ofiarę",
                path: "/offerings",
            },
            {
                name: "Cmentarze",
                path: "https://bielsko-luteranie.grobonet.com/grobonet/",
            },
            {
                name: "Stary cmentarz ewangelicki",
                path: "https://www.facebook.com/people/Stary-Cmentarz-Ewangelicki-w-Bielsku/100064505359921/",
            }
        ]
    },
    {
        name: "Senior",
        links: [
            {
                name: "Soar",
                path: "/soar"
            },
            {
                name: "Informacje",
                path: "/senior"
            }
        ]
    },
];