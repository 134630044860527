import React, { createContext, useContext, useState, ReactNode } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

import { admin_api } from "../services/api";

interface jwtPayload {
  exp: number;
}

interface AuthContextType {
  isAuthenticated: boolean;
  login: (username: string, password: string) => Promise<[boolean, string]>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = async (
    username: string,
    password: string
  ): Promise<[boolean, string]> => {
    try {
      const response = await axios.post(
        "http://localhost:8000/api/dashboard/login/",
        {
          username: username,
          password: password,
        }
      );
      localStorage.setItem("accessToken", response.data.access);
      localStorage.setItem("refreshToken", response.data.refresh);

      setIsAuthenticated(true);

      return [true, ""];
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return [false, `${error.response.status}`];
      }
      return [false, "Unknown error"];
    }
  };

  const logout = async () => {
    const refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken) {
      try {
        await admin_api.post("logout/", { refresh: refreshToken });
      } catch (error) {
        console.log("Error logging out: ", error);
      }
    }

    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const isTokenExpired = (token: string): boolean => {
  if (!token) {
    return true;
  }
  const { exp } = jwtDecode<jwtPayload>(token);

  if (exp * 1000 < Date.now()) {
    return true;
  }
  return false;
};

export const isLoggedIn = () => {
  const token = localStorage.getItem("accessToken");
  return token !== null && !isTokenExpired(token);
};
