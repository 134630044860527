import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import { PostType } from "../../types/Post";
import { NavigationLinks, PageColors } from "../../constants";

function FindPath(name: string): string | undefined {
  const link = NavigationLinks.find(
    (link) =>
      link.name === name || link.links?.some((link2) => link2.name === name)
  );

  if (link && link.links) {
    return link.links.find((link2) => link2.name === name)?.path;
  }

  return link?.path;
}

const PostTags: {
  [key: string]: { bgColor: string; textColor?: string; path?: string };
} = {
  default: {
    bgColor: PageColors["Ogłoszenia"]["titleBackground"],
    textColor: PageColors["Ogłoszenia"]["titleColor"],
  },
  Ogłoszenia: {
    bgColor: PageColors["Ogłoszenia"]["titleBackground"],
    textColor: PageColors["Ogłoszenia"]["titleColor"],
    path: FindPath("Ogłoszenia"),
  },
  Publikacje: {
    bgColor: PageColors["Publikacje"]["titleBackground"],
    textColor: PageColors["Publikacje"]["titleColor"],
    path: FindPath("Publikacje"),
  },
};

interface PostProps {
  post?: PostType;
  className?: string;
}

const Post: React.FC<PostProps> = (props: PostProps) => {
  if (props.post) {
    return (
      <div
        className={`flex flex-col min-w-[350px] max-h-[550px] text-foreground ${props.className}`}
      >
        <div
          id="PostThumbnail"
          className="w-full min-h-[240px] object-center object-contain rounded-t-2xl bg-purple-300"
          style={{ backgroundImage: `url(${props.post.thumbnail})` }}
        />
        <div
          id="PostContent"
          className="flex flex-col gap-y-4 p-6 bg-purple-50 border-2 border-purple-500 rounded-b-2xl w-full h-full"
        >
          <div id="PostTags" className="flex flex-row gap-x-2">
            {props.post.tags.map((tag, index) => (
              <PostTag title={tag.name} key={index} />
            ))}
          </div>
          <div className="flex flex-col gap-y-3">
            <h3 id="PostTitle" className="semiboldheader3">
              <Link to={`/post/${props.post.slug}`}>{props.post.title}</Link>
            </h3>
            <p id="PostDescription" className="text-clip max-h-[150px]">
              {props.post.description}
            </p>
          </div>
          <div id="PostDate" className="flex flex-row w-full justify-end">
            <p className="opacity-80 w-max">
              {format(new Date(props.post.created), "dd-MM-yyyy")}
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`flex flex-col min-w-[350px] max-h-[550px] text-foreground bg-background rounded-2xl ${props.className}`}
      >
        <div
          id="PostThumbnail"
          className="w-full min-h-[240px] rounded-t-2xl bg-purple-300 opacity-80"
        >
          <div className="animate-pulse bg-purple-500 w-full h-[240px] rounded-t-2xl" />
        </div>
        <div
          id="PostContent"
          className="flex flex-col gap-y-4 p-6 bg-purple-50 border-2 border-purple-500 rounded-b-2xl w-full h-full opacity-70"
        >
          <div id="PostTags" className="flex flex-row gap-x-2">
            <PostTag />
            <PostTag />
          </div>
          <div className="flex flex-col gap-y-3">
            <div className="animate-pulse h-8 w-[180px] bg-purple-500 rounded-2xl" />
            <div
              id="PostDescription"
              className="flex flex-col gap-y-1 max-h-[150px]"
            >
              <div className="animate-pulse h-4 w-[250px] bg-purple-500 rounded-xl" />
              <div className="animate-pulse h-4 w-[200px] bg-purple-500 rounded-xl" />
            </div>
          </div>
          <div id="PostDate" className="flex flex-row w-full justify-end">
            <div className="animate-pulse h-2 w-[80px] bg-purple-500 rounded-md" />
          </div>
        </div>
      </div>
    );
  }
};

interface PostTagProps {
  title?: string;
}

const PostTag: React.FC<PostTagProps> = (props: PostTagProps) => {
  const postTag = PostTags[props.title ?? "default"];

  if (props.title) {
    return (
      <div
        className={`p-2 rounded-xl bg-opacity-80`}
        style={{ backgroundColor: postTag.bgColor }}
      >
        {postTag.path ? (
          <Link
            to={postTag.path}
            className="basetext"
            style={{ color: postTag.textColor }}
          >
            {props.title}
          </Link>
        ) : (
          <p className="basetext" style={{ color: postTag.textColor }}>
            {props.title}
          </p>
        )}
      </div>
    );
  } else {
    return (
      <div
        className={`p-2 rounded-xl bg-opacity-80`}
        style={{ backgroundColor: postTag.bgColor }}
      >
        <div
          className="w-16 h-2 rounded-md bg-opacity-50 animate-pulse"
          style={{ backgroundColor: postTag.textColor }}
        />
      </div>
    );
  }
};

export default Post;
