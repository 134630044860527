import React from "react";

const NotFound: React.FC = () => {
  return (
    <main className="flex flex-col items-center min-h-[40vh]">
      <div className="flex flex-col items-center my-auto">
        <h2 className="semiboldheader1">404 - Not Found</h2>
        <p>Strona której szukasz, nie istnieje.</p>
      </div> 
    </main>
  );
};

export default NotFound;
