import React from "react";

import { HeroBanner } from "../img";
import DailyVerses from "../components/LandingPage/DailyVerses";
import News from "../components/LandingPage/News";

const LandingPage: React.FC = () => {
  return (
    <main className="flex flex-col gap-y-16 mb-16">
      <div
        id="Hero"
        className="relative lg:px-[11.25%] w-full h-[720px]"
        style={{
          backgroundImage: `url(${HeroBanner})`,
          backgroundPosition: "center",
        }}
      >
        <div className="absolute backgroundImageFilter" />
        <div className="md:hidden absolute backgroundImageFilterMobile" />
        <div className="absolute backgroundImageFilter2" />
        <div
          id="HeroTitle"
          className="absolute rounded-2xl bg-purple-200 bg-opacity-60 mx-auto left-0 right-0 lg:ml-[11.25%] w-fit p-6 mt-8"
        >
          <h1 className="semiboldheader1 md:boldheader1 text-[#FAF4FB]">
            Parafia
            <br />
            <span className="text-[#8E215F]">Ewangelicko-Augsburska</span>
            <br />w Bielsku-Białej
          </h1>
        </div>
      </div>
      <DailyVerses />
      <News />
    </main>
  );
};

export default LandingPage;
