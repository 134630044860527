import React from "react";
import { Link } from "react-router-dom";

import { FooterBanner, Logo, Facebook, Youtube } from "../img";
import Spacer from "./ui/Spacer";

const Footer: React.FC = () => {
  return (
    <footer
      className="relative w-full mt-auto bg-cover"
      style={{
        backgroundImage: `url(${FooterBanner})`,
        backgroundPosition: "center",
      }}
    >
      <div className="absolute backgroundImageFilterLight"></div>
      <div className="md:hidden absolute backgroundImageFilterMobile"></div>
      <Spacer variant="WhiteDown" />
      <div
        id="FooterContent"
        className="relative px-8 md:px-[11.25%] flex flex-col w-full lg:flex-row lg:justify-between lg:items-start gap-y-8 py-8 pb-12 md:pb-8 basetext text-foreground"
      >
        <div
          id="FooterLogotype"
          className="flex flex-col gap-y-4 p-4 items-center bg-purple-50 border-2 border-purple-500 rounded-2xl self-center lg:self-start"
        >
          <Logo className="w-24 h-24" />
          <p className="text-foreground semiboldheader3">
            Parafia
            <br />
            Ewangelicko-Augsburska
            <br />w Bielsku-Białej
          </p>
        </div>
        <div
          id="FooterSocials"
          className="flex flex-col gap-y-4 p-4 bg-purple-50 border-2 border-purple-500 rounded-2xl self-center lg:self-start"
        >
          <p className="text-foreground semiboldheader3">Znajdź nas na:</p>
          <Link
            className="flex flex-row gap-x-2 items-center"
            to="https://www.facebook.com/profile.php?id=100079543970479"
          >
            <Facebook className="w-8 h-8" />
            <p className="basetext clickable">Facebook</p>
          </Link>
          <Link
            className="flex flex-row gap-x-2 items-center"
            to="https://www.youtube.com/@parafiaewangelicko-augsbur5230"
          >
            <Youtube className="w-8 h-8" />
            <p className="basetext clickable">Youtube</p>
          </Link>
        </div>
        <div
          id="FooterContact"
          className="flex flex-col gap-y-4 p-4 bg-purple-50 border-2 border-purple-500 rounded-2xl self-center lg:self-start"
        >
          <p className="text-foreground semiboldheader3">Kontakt:</p>
          <address className="flex flex-col gap-y-2 basetext text-foreground not-italic">
            <p>Parafia Ewangelicko-Augsburska Bielsko</p>
            <p>
              <a
                href="https://maps.app.goo.gl/NhPcUi1VkXVpt7dN8"
                className="clickable"
              >
                Pl. ks. Marcina Lutra 12
              </a>
            </p>
            <p>43-300 Bielsko-Biała</p>
            <p>
              Telefon:{" "}
              <a href="tel:+48338227471" className="clickable">
                +48 33 82 274 71
              </a>
            </p>
            <p>
              E-mail:{" "}
              <a href="mailto:bielsko@luteranie.pl" className="clickable">
                bielsko@luteranie.pl
              </a>
            </p>
          </address>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
