import React from "react";
import { Link } from "react-router-dom";

import { BannerKeys } from "../../img";
import StaticPageLayout from "../../components/StaticPages/Layout";

import {
  GradualeThumbnail,
  Graduale01,
  Graduale02,
  Graduale03,
  Graduale04,
  Graduale05,
} from "../../img";
import ImageGallery from "../../components/ui/ImageGallery";

const Graduale: React.FC = () => {
  return (
    <StaticPageLayout
      title='Luterański Chór "GRADUALE"'
      background={BannerKeys}
    >
      <div className="flex flex-row w-full justify-center text-foreground">
        <div className="flex flex-col gap-y-2 items-center">
          <img
            src={GradualeThumbnail}
            className="max-w-64 max-h-64 md:max-w-96 md:max-h-96"
          />
          <p className="opacity-80 footertext">
            Na zdjęciu: koncert kolęd w Galerii Handlowej SFERA
          </p>
        </div>
      </div>
      <p>
        Luterański Chór GRADUALE działający do niedawna pod nazwą Chór Parafii
        Ewangelicko-Augsburskiej przy kościele „Zbawiciela” w Bielsku-Białej
        powstał w 1945r. Jego założycielką oraz długoletnią dyrygentką (lata
        1945-1995) była Pani Seniorowa Maria Wegert. Od roku 1996 chórem kieruje
        mgr Agata Fender, absolwentka Akademii Muzycznej w Krakowie.
        <br />
        Poprzez swoją działalność chór kontynuuje wielkie przedwojenne tradycje
        muzyczne bielskiej parafii. W swoim repertuarze ma utwory sakralne
        kompozytorów polskich i obcych, od renesansu po współczesność (min. M.
        Gomółka, Wacław z Szamotuł, G. G. Gorczycki, J. S. Bach, H. Schutz, M.
        Praetorius, W. A. Mozart, A. Bruckner, H. Diestler, F. Nowowiejski, J.
        Świder, R. Twardowski), ważne miejsce zajmują też kompozycje, oraz wiele
        ciekawych aranżacji pieśni sakralnych, ludowych, biesiadnych i
        towarzyskich autorstwa znamienitych twórców wywodzących się ze Śląska
        Cieszyńskiego - J. Gawlasa, J. Hadyny, K. Hławiczki, oraz szczególnie
        nam bliskiego Pana mgr Kazimierza Dudy.
        <br />
        W ciągu ponad 60- ciu lat swego istnienia chór wielokrotnie koncertował
        w kraju i zagranicą (min. Czechy, Węgry, Austria, Szwecja, Niemcy,
        Holandia, Dania, Słowacja, Rosja) śpiewając w największych luterańskich
        katedrach Europy (Ulm, Uppsala) ale i w małych kościółkach, domach
        opieki, ośrodkach dla osób niepełnosprawnych. Chór na „co dzień” służy
        przede wszystkim w macierzystej parafii i podczas uroczystości w
        Kościołach Luterańskich na terenie całego kraju; ma również znaczące
        miejsce w środowisku, w którym działa, będąc częstym gościem na
        nabożeństwach ekumenicznych, koncertach charytatywnych, biorąc udział w
        imprezach organizowanych przez różne instytucje a także uczestnicząc w
        przeglądach, festiwalach i imprezach chóralnych organizowanych w Naszym
        Mieście.
        <br />
        <br />
        Chór GRADUALE dowiedz się więcej odwiedź naszą stronę na{" "}
        <Link to="https://www.facebook.com/chorgraduale/" className="clickable">
          Facebooku
        </Link>
      </p>
      <ImageGallery
        images={[Graduale01, Graduale02, Graduale03, Graduale04, Graduale05]}
      />
    </StaticPageLayout>
  );
};

export default Graduale;
