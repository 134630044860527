import React, { useState, useEffect } from "react";

import Spacer from "../ui/Spacer";

type Verse = {
  text: string;
  author: string;
};

const DailyVerses: React.FC = () => {
  const [verses, setVerses] = useState<Verse[]>([]);

  return (
    <div className="flex flex-col w-full">
      <Spacer variant="BlueUp" />
      <div
        id="DailyVercesContent"
        className="flex flex-col gap-y-6 px-8 md:px-[11.25%] bg-accent"
      >
        <div id="Title" className="flex flex-row">
          <h2 className="text-purple-500 boldheader3 md:boldheader2">
            Z Biblią na co dzień
          </h2>
        </div>
        <div id="Verses" className="flex flex-col gap-y-5">
          {verses.length > 0 ? (
            <div>{/* TODO: Fetch and display content here */}</div>
          ) : (
            <>
              <div className="flex flex-col gap-y-4 max-w-3xl">
                <div className="flex flex-col gap-y-2">
                  <div className="bg-foreground h-3 opacity-60 w-[80%] rounded-md animate-pulse" />
                  <div className="bg-foreground h-3 opacity-60 w-[40%] rounded-md animate-pulse" />
                </div>
                <div className="bg-purple-300 h-6 opacity-80 w-[20%] rounded-md animate-pulse" />
              </div>
              <div className="flex flex-col gap-y-4 max-w-3xl">
                <div className="flex flex-col gap-y-2">
                  <div className="bg-foreground h-3 opacity-60 w-[50%] rounded-md animate-pulse" />
                </div>
                <div className="bg-purple-300 h-6 opacity-80 w-[30%] rounded-md animate-pulse" />
              </div>
              <div className="flex flex-col gap-y-4 max-w-3xl">
                <div className="flex flex-col gap-y-2">
                  <div className="bg-foreground h-3 opacity-60 w-[90%] rounded-md animate-pulse" />
                  <div className="bg-foreground h-3 opacity-60 w-[60%] rounded-md animate-pulse" />
                </div>
                <div className="bg-purple-300 h-6 opacity-80 w-[15%] rounded-md animate-pulse" />
              </div>
            </>
          )}
        </div>
      </div>
      <Spacer variant="BlueDown" />
    </div>
  );
};

export default DailyVerses;
