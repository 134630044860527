import React from "react";

import { PageColors } from "../../constants";

interface PostPageLayoutProps {
  title: string;
  children: React.ReactNode;
  backgroundImage?: string;
}

const PostPageLayout: React.FC<PostPageLayoutProps> = (
  props: PostPageLayoutProps
) => {
  const backgroundColor =
    PageColors[props.title]["titleBackground"] ||
    PageColors["default"]["titleBackground"];
  const titleColor =
    PageColors[props.title]["titleColor"] ||
    PageColors["default"]["titleColor"];

  return (
    <main className="relative flex-col w-full">
      <div
        id="StaticPageBackground"
        className="absolute w-full h-[500px] bg-cover bg-center -z-10"
        style={{
          backgroundImage: `url(${props.backgroundImage})`,
          backgroundPosition: "center",
        }}
      >
        <div className="absolute backgroundImageFilter" />
        <div className="md:hidden absolute backgroundImageFilterMobile" />
        <div className="absolute backgroundImageFilter2" />
      </div>
      <div className="relative w-full h-max">
        <div className="py-12 mx-8 lg:mx-[11.25%] flex flex-col gap-y-8">
          <div
            className={`flex flex-row p-4 rounded-2xl md:w-max justify-center md:justify-start`}
            style={{ backgroundColor: backgroundColor }}
          >
            <h2
              className="boldheader3 lg:boldheader2 2xl:boldheader1 text-purple-500"
              style={{ color: titleColor }}
            >
              {props.title}
            </h2>
          </div>
          <div className="flex flex-wrap sm:basis-[34%] lg:basis-[26%] grow-1 basetext text-foreground w-full gap-y-6">
            {props.children}
          </div>
        </div>
      </div>
    </main>
  );
};

export default PostPageLayout;
