import React, { useState, useEffect } from "react";

import PostPageLayout from "../../components/PostPages/Layout";
import Post from "../../components/ui/Post";
import { getPosts } from "../../services/postService";
import { PostType } from "../../types/Post";
import { BannerClock } from "../../img";

const News: React.FC = () => {
  const [posts, setPosts] = useState<PostType[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await getPosts("Ogłoszenia");

      setPosts(response);
    };
    fetchPosts();
    console.log(posts);
  }, []);

  return (
    <PostPageLayout title="Ogłoszenia" backgroundImage={BannerClock}>
      {posts.length === 0
        ? [...Array(12)].map((_, index) => (
            <Post
              key={index}
              className="basis-[51%] lg:basis-[34%] 2xl:basis-[26%] grow-1 mx-auto"
            />
          ))
        : posts.map((post, index) => (
            <Post
              post={post}
              key={index}
              className="basis-[51%] lg:basis-[34%] 2xl:basis-[26%] grow-1 mx-auto"
            />
          ))}
    </PostPageLayout>
  );
};

export default News;
